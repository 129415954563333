'use strict';

Gri.module({
	name: 'accordion',
	ieVersion: null,
	$el: $('.accordion'),
	container: '.accordion',
	fn: function () {

		$(".fon-accordion").accordion({
			collapsible: true,
			active: false,
			heightStyle: "content"
		});

		$("#individual-pension-accordion").accordion({
			collapsible: true,
			active: false,
			heightStyle: "content"
		});

		$("#mechanism-accordion").accordion({
			collapsible: true,
			active: false,
			heightStyle: "content"
		});

		$(".custom-accordion").accordion({
			collapsible: true,
			active: false,
			heightStyle: "content"
		});

		if (hasSearchURL()) {
			$("#law-accordion").accordion({
				active:false,
				disabled:true,
				collapsible: true,
				heightStyle: "content"
			});
		} else {
			$("#law-accordion").accordion({
				collapsible: true,
				active: false,
				heightStyle: "content"
			});
		}

		$("#law-accordion-mobile").accordion({
			collapsible: true,
			active: true,
			heightStyle: "content"
		});

		function hasSearchURL(){
			var result = true;
			if (window.location.href.search("search") == -1) {
				result = false;
			}
			return result;
		}

		if(hasSearchURL()) {
			$('.accordion .accordion-title').addClass("minus");
			$('.accordion .accordion-title').click(function(){
				$(this).next().slideToggle();
				if($(this).hasClass('minus')) {
					$(this).removeClass("minus").addClass("plus");
				}else{
					$(this).removeClass("plus").addClass("minus");
				}

		  	});
			$('.accordion .ui-accordion-content').slideDown();
		}



	}
});
